import { GAEvent } from '@/services/ga/events/GAEvent';
import { EntityApiType } from '@/utils/types/EntityApiType';

export class EventViewEntitySidebar extends GAEvent {
  public eventName = 'view-entity-sidebar';
  public productId?: number;
  public entityType?: EntityApiType;
  public entityId?: number;

  constructor(
    productId?: number,
    entityType?: EntityApiType,
    entityId?: number
  ) {
    super();
    this.productId = productId;
    this.entityType = entityType;
    this.entityId = entityId;
  }
}
