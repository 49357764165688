
import DraggablePanel from '@/components/buttons/DraggablePanel.vue';
import XlsxDownloader from '@/components/buttons/XlsxDownloader.vue';
import LastUpdated from '@/components/common/LastUpdated.vue';
import MobileHeader from '@/components/common/MobileHeader.vue';
import RouteVolumeReading from '@/components/common/RouteVolumeReading.vue';
import Searchbar from '@/components/common/Searchbar.vue';
import GoogleMap from '@/components/GoogleMap.vue';
import ListView from '@/components/list/ListView.vue';
import AppName from '@/components/mixin/AppName.vue';
import ConstantsMixin from '@/components/mixin/Constants.vue';
import FeedFiltersModal from '@/components/modal/FeedFiltersModal.vue';
import FuelFiltersModal from '@/components/modal/FuelFiltersModal.vue';
import MilkFiltersModal from '@/components/modal/MilkFiltersModal.vue';
import {
  isWaterTank,
  isWaterMeter,
  returnOnEntityAccess
} from '@/utils/WaterUtils';
import WaterTankFiltersModal from '@/components/modal/WaterTankFiltersModal.vue';
import WaterMeterFiltersModal from '@/components/modal/WaterMeterFiltersModal.vue';
import SideContent from '@/components/sidebar/SideContent.vue';
import TableSearchbar from '@/components/table/TableSearchbar.vue';
import BoundingBox from '@/services/api/models/BoundingBox';
import { trackEvent } from '@/services/Mixpanel';
import { setHasNonFonterra, storeState } from '@/store/Globals';
import ColumnOrderMoveEvent from '@/store/models/ColumnOrderMoveEvent';
import { AppFilters, SavedAppFilters } from '@/store/models/Filters';
import { MilkMetrics, Product } from '@/store/models/Product';
import SortingInfo from '@/store/models/SortingInfo';
import { TableColumnFormatters } from '@/store/models/TableColumn';
import ProductStore from '@/store/modules/ProductStore';
import User from '@/store/modules/User';
import { isApp } from '@/utils/AppName';
import {
  adjustAndSetColumnOrder,
  orderColumnsBySavedOrder,
  removeSavedColumnOrder
} from '@/utils/ColumnOrdering';
import { Constants } from '@/utils/Constants';
import { getHasNonFonterra } from '@/utils/formatters/MilkEntitiesFormatter';
import {
  formatAppProducts,
  getAppDefaultTableFields,
  getAppFilter,
  getAppMapTag,
  getAppRoute,
  getAppTableColumnFormatter,
  getVisibleAppTableColumnFormatters
} from '@/utils/GenericUtils';
import {
  getObjectItem,
  getUserItem,
  setObjectItem,
  setUserItem
} from '@/utils/LocalStorageUtils';
import { nestedEntitiesSorter } from '@/utils/SortingUtils';
import { milkSpecificTableColumnsFormatters } from '@/utils/table-fields/MilkTableFields';
import { getPageTableFieldList } from '@/utils/table-fields/TableFieldLists';
import { tableColumnFormatters } from '@/utils/table-fields/TableFields';
import { isAdmin } from '@/utils/TestingValidity';
import { AllowedField, MilkAllowedField } from '@/utils/types/AllowedFields';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Ref, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { GA } from '@/services/ga/GoogleAnalytics';
import { EventFilterEntities } from '@/services/ga/events/EventFilterEntities';
import { EventChangeOrg } from '@/services/ga/events/EventChangeOrg';
import { EventSearchCustomers } from '@/services/ga/events/EventSearchCustomers';
import { EventDownloadCSV } from '@/services/ga/events/EventDownloadCSV';
import { EventClearMapBounds } from '@/services/ga/events/EventClearMapBounds';
import { EventViewEntitySidebar } from '@/services/ga/events/EventViewEntitySidebar';
import { EventSidebarChangeEntity } from '@/services/ga/events/EventSidebarChangeEntity';
import UnitSettingsModal from '@/components/modal/UnitSettingsModal.vue';
import CustomStore from '@/store/modules/CustomStore';
import { filterWaterTableCols } from '@/utils/WaterUtils';
import { getMapSettings } from '@/utils/MapUtils';

@Component({
  components: {
    ListView,
    TableSearchbar,
    GoogleMap,
    XlsxDownloader,
    DraggablePanel,
    SideContent,
    MilkFiltersModal,
    FuelFiltersModal,
    FeedFiltersModal,
    WaterTankFiltersModal,
    WaterMeterFiltersModal,
    RouteVolumeReading,
    LastUpdated,
    MobileHeader,
    Searchbar,
    UnitSettingsModal
  },
  methods: {
    isAdmin,
    isWaterTank,
    isWaterMeter
  },
  computed: {
    allProducts: () => ProductStore.currentAllProducts ?? [],
    ...mapGetters('user', {
      currentUser: 'currentUser',
      token: 'token'
    })
  },
  watch: {
    $route: function() {
      this.$root.$emit('pageChanged');
    }
  }
})
export default class ProductView extends mixins(AppName, ConstantsMixin) {
  @Ref('map') readonly googleMap!: GoogleMap;
  public boundingBox: BoundingBox = {
    southWest: { lat: -46.42390503374751, lon: 140.32829142386333 },
    northEast: { lat: -29.536734701401574, lon: 180 }
  };
  public allProductBoundingBox: BoundingBox = Constants.ALLPRODUCTBOUNDINGBOX;
  public collapseMap = false;
  // public enableClustering = false;
  public products: Product[] = [];
  public entities: Product[] = [];
  public sortBy = 'supplierNumber';
  public searchTerm = '';
  private firstLoad = true;
  private selectedProductId: number | null = null;
  public entityState: Product | null = null;
  public increasing = true;
  public selected = false;
  public sidebarPos = 0;
  public entityIndex = -1;
  public mPos: any;
  public contentWidth = 1920;
  public mapHeight = 500;
  public prevMapHeight = 500;
  // public prevZoom = 6;
  public prevSelectedIndex = -1;
  // public ignoreBoundingBox = false;
  // public ignoreClustering = false;
  // public markerClusterer!: MarkerClusterer;
  public reloadMarkers = false;

  // Getting the correct filters for the product
  public filters = getAppFilter();
  public width = 1000;
  public loadingProducts = true;
  public loadingProduct = true;
  public paginatedProducts: Product[] = [];
  public offset = 0;
  public perPageLimit = 25;
  public route = getAppRoute();
  public totalVolume = 0;
  public updatedFilters = false;
  public isFilterDropVisible = false;
  public filterByMap = false;
  public activeSearch = false;
  public isRouteView = false;
  public active = '';
  public mapSettings: { mapTag: string } | null = null;
  public currentApp = 'milk';
  public tableColumns: any[] = [];
  public sortInfo: SortingInfo = {
    sortBy: null,
    increasing: true
  };
  public lastUpdatedTimestamp: number | null = null;

  get selectedProduct() {
    if (this.selectedProductId) {
      ProductStore.updateCurrentProductState(
        this.products.find(
          product => product.productId === this.selectedProductId
        )
      );
      return this.products.find(
        product => product.productId === this.selectedProductId
      );
    }
    return null;
  }

  get storeLoadingState() {
    return storeState.loading;
  }

  mounted() {
    this.currentApp = this.getAppName(false);
    trackEvent(`User viewing ${this.getAppName(false)} products`);
    this.whatsthewidth();
    if (window.innerWidth < 1000) {
      this.prevMapHeight = window.innerHeight - 135;
    } else {
      this.mapHeight = window.innerHeight / 2;
      this.prevMapHeight = this.mapHeight;
    }
    window.addEventListener('resize', this.whatsthewidth);
    if (!this.storeLoadingState) {
      this.loadPageFields();
    }

    this.$root.$on('bv::dropdown::show', (bvEvent: any) => {
      if (
        bvEvent.componentId === 'sortDrop' ||
        bvEvent.componentId === 'xlsxDrop'
      ) {
        this.isFilterDropVisible = true;
      }
    });
    this.$root.$on('bv::dropdown::hide', (bvEvent: any) => {
      if (
        bvEvent.componentId === 'sortDrop' ||
        bvEvent.componentId === 'xlsxDrop'
      ) {
        this.isFilterDropVisible = false;
      }
      if (this.isFilterDropVisible) {
        bvEvent.preventDefault();
      }
    });
  }

  created() {
    this.$root.$on('customFieldsUpdated', () => {
      this.fetchEntities(this.boundingBox);
      setTimeout(() => {
        this.getEntityState();
      }, 1000);
    });
    this.$root.$on('orgIndexChanged', () => {
      this.updateData();
    });

    this.$root.$on('offsetChanged', this.changeOffset);
    this.$root.$on(
      'columnOrderChanged',
      (move: ColumnOrderMoveEvent, columns: any[]) => {
        this.tableColumns = columns;
        adjustAndSetColumnOrder(move);
      }
    );
    this.$root.$on('pageChanged', () => {
      if (this.getAppName(false) != this.currentApp) {
        window.location.reload();
      }
      this.closeSidebar();
      this.resetPage();
      this.getPage();
      this.filterProducts();
    });
    this.$root.$on('setEntityIndex', this.entitySelected);
    this.$root.$on('mapSettingsUpdated', () => this.settingsUpdated());

    this.$root.$on('routeUpdated', () => {
      this.route.update();
      this.updateRouteTotalVolume();
      this.route.getTotalVolume();
    });
  }

  destroyed() {
    window.removeEventListener('resize', this.whatsthewidth);
  }

  public settingsUpdated() {
    this.loadingProducts = true;
    const mapSettings = getMapSettings();
    if (mapSettings) {
      this.mapSettings = mapSettings;
    }
    if (this.googleMap && this.googleMap.markers) {
      const allMarkerIds = Object.keys(this.googleMap.markers);
      allMarkerIds.forEach(markerId => {
        this.googleMap.markers[parseInt(markerId, 10)].setMap(null);
        delete this.googleMap.markers[parseInt(markerId, 10)];
      });
    }

    this.filterProducts();

    this.loadingProducts = false;
  }

  public showUnitSettingsModal() {
    this.$root.$emit('bv::show::modal', 'unitSettingsModal', '#btnShow');
  }

  public async updateData() {
    this.fetchEntities(this.boundingBox);
    this.loadAllEntities();
  }

  public async loadPageFields() {
    await this.loadSavedPreferences();
    await this.loadTableColumns();
  }

  public async loadTableColumns() {
    getVisibleAppTableColumnFormatters(
      getAppDefaultTableFields(getPageTableFieldList()),
      true
    ).then(columns => {
      if (isApp(this.PRODUCT_TYPE_WATER)) {
        columns = filterWaterTableCols(columns);
      }
      this.tableColumns = orderColumnsBySavedOrder(columns);
      if (this.sortInfo.sortBy == null) {
        this.sortInfo.sortBy = isApp(this.PRODUCT_TYPE_MILK)
          ? milkSpecificTableColumnsFormatters[
              MilkAllowedField.SUPPLIER_NUMBER
            ] ?? null
          : tableColumnFormatters[AllowedField.SITE] ?? null;
      }
      this.getPage();
    });
  }

  public async loadSavedPreferences() {
    if (this.width > 1000) {
      const mapHeight = getObjectItem(`${this.getAppName(false)}MapHeight`);
      if (mapHeight) {
        this.mapHeight = mapHeight;
        this.prevMapHeight = this.mapHeight;
      }
    }
    const hideMap = getObjectItem(`${this.getAppName(false)}HideMap`);
    if (hideMap === true && !this.collapseMap) {
      this.hideMap();
    }
    const perPage = getUserItem(`${this.getAppName(false)}PerPage`);
    if (perPage) {
      this.perPageLimit = +perPage;
    }
    const mapSettings = getMapSettings();
    if (mapSettings) {
      this.mapSettings = mapSettings;
    } else {
      this.mapSettings = { mapTag: 'age' };
    }
    const sortBy = getObjectItem(`${this.getAppName(false)}SortBy`);
    if (sortBy && sortBy.sortBy) {
      const column = await getAppTableColumnFormatter(sortBy.sortBy.name);
      sortBy.sortBy = column;

      this.sortInfo = sortBy;
    }
  }

  public getPage() {
    this.loadingProducts = true;
    const route = this.$route.path;
    if (route.includes('view')) {
      this.isRouteView = false;
      const mapBounds = getObjectItem(`${this.getAppName(false)}MapBounds`);
      if (mapBounds) {
        this.filterByMap = true;
        this.fetchEntities(this.boundingBox);
      } else {
        this.loadingProducts = false;
      }
    } else if (route.includes('route-plan')) {
      this.isRouteView = true;
      this.fetchEntities(this.allProductBoundingBox);
      this.filterByMap = false;
    }
  }

  public updateWidth(
    changeToSmallScreen: boolean,
    changeToWideScreen: boolean
  ) {
    if (changeToSmallScreen) {
      this.collapseMap = true;
      this.prevMapHeight = window.innerHeight - 128;
      this.mapHeight = 0;
    } else if (changeToWideScreen) {
      this.prevMapHeight = window.innerHeight / 2;
      const mapHeight = getObjectItem(`${this.getAppName(false)}MapHeight`);
      if (mapHeight) {
        this.prevMapHeight = mapHeight;
      }
    }
    this.width = window.innerWidth;

    if (this.width < 1000 && !this.collapseMap) {
      this.mapHeight = window.innerHeight - 128;
    } else if (this.width >= 1000 && !this.collapseMap) {
      this.mapHeight = this.prevMapHeight;
    }
  }

  @Watch('storeLoadingState')
  storeStateUpdated() {
    if (!this.storeLoadingState) {
      this.loadPageFields();
    }
  }

  @Watch('paginatedProducts')
  paginatedProductsUpdated() {
    this.updateMap();
  }

  @Watch('filters.filters', { deep: true })
  handleFilter() {
    this.checkFiltersUpdated();
    this.filterProducts();
  }

  @Watch('selectedProductId')
  getEntityState() {
    this.loadingProduct = true;
    if (this.selectedProductId) {
      const selectedProduct = this.products.find(
        product => product.productId === this.selectedProductId
      );
      if (selectedProduct) {
        this.entityState = selectedProduct;
        ProductStore.updateCurrentProductState(selectedProduct);
      } else {
        this.entityState = null;
      }
    } else {
      this.entityState = null;
    }
    setTimeout(() => {
      this.whatsthewidth();
      this.loadingProduct = false;
    }, 500);
  }

  get sortableFields() {
    if (this.tableColumns) {
      const sortable: TableColumnFormatters[] = [];
      this.tableColumns.forEach((column: TableColumnFormatters) => {
        if (column.sortable) {
          sortable.push(column);
        }
      });
      return sortable;
    }
    return [];
  }

  public whatsthewidth() {
    const width = document.getElementById('mainContent')?.offsetWidth;
    let changetoSmall = false;
    let changeToWide = false;
    if (width) {
      if (this.contentWidth >= 1000 && width < 1000) {
        changetoSmall = true;
      }
      if (this.contentWidth < 1000 && width >= 1000) {
        changeToWide = true;
      }
      this.contentWidth = width;
    }
    this.updateWidth(changetoSmall, changeToWide);
  }

  // Called when map pans / zooms - it emits mapDidChange event.
  // NOTE: this also fires when the map loads for the first time.
  public mapDidChangeHandler(boundingBox: BoundingBox): void {
    if (boundingBox.southWest.lat == boundingBox.northEast.lat) {
      this.fetchEntities(this.boundingBox);
    } else {
      this.boundingBox = boundingBox;
      this.fetchEntities(boundingBox);
    }
  }

  private fetchEntities(boundingBox?: BoundingBox): void {
    this.loadingProducts = true;
    ProductStore.fetchEntityStates({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      productRequest: {
        boundingBox,
        productType: this.getAppName(false)
      }
    }).then(async products => {
      this.entities = products;

      this.filterProducts();
      this.lastUpdatedTimestamp = dayjs().unix();
      this.updateRouteTotalVolume();
      this.loadingProducts = false;
    });
  }

  private async loadAllEntities() {
    this.loadingProducts = true;
    await ProductStore.fetchAllEntityStates(this.getAppName(false));
    this.updateRouteTotalVolume();
    this.loadingProducts = false;
  }

  public resetMap(): void {
    this.loadingProducts = true;
    this.mapHeight =
      this.width < 1000 ? this.mapHeight : window.innerHeight / 2;
    this.boundingBox = this.allProductBoundingBox;
    this.filterByMap = false;

    GA.event<EventClearMapBounds>(this.$gtag, new EventClearMapBounds());

    setTimeout(() => {
      this.$root.$emit('updateListSize');
      this.$root.$emit('resetMap');
      this.fetchEntities(this.allProductBoundingBox);
      this.loadingProducts = false;
    }, 500);
  }

  public resetColumnOrder(): void {
    removeSavedColumnOrder();
    this.loadTableColumns();
  }

  public checkFiltersUpdated() {
    this.updatedFilters = this.filters.filtersSet;
  }

  public saveFilters(saveFilters: SavedAppFilters): void {
    setObjectItem(`${this.getAppName(false)}Filters`, {
      searchTerm: this.searchTerm,
      filters: saveFilters
    });
    trackEvent(`User saved ${this.getAppName(false)} filters`);
    this.$bvToast.toast(
      'Your current filter selection has been saved to your browser',
      {
        title: 'Filters Saved',
        toaster: 'b-toaster-bottom-center',
        solid: true,
        append: false
      }
    );
  }

  public updatePaginatedProducts() {
    this.loadingProducts = true;

    //When sorting changes, need to updated selected index so the same entity stays selected
    let currentlySelectedEntityId: number | null = null;
    if (this.selectedProduct) {
      currentlySelectedEntityId = this.selectedProduct?.entities
        ? this.selectedProduct?.entities[this.entityIndex ?? 0].entityId
        : null;
    }
    setTimeout(() => {
      this.products = nestedEntitiesSorter([...this.products], this.sortInfo);
      this.paginatedProducts = [...this.products].splice(
        this.offset,
        this.perPageLimit
      );
      this.getEntityState();
      this.selectEntityById(currentlySelectedEntityId);
      this.loadingProducts = false;
    }, 500);
  }

  private updateRouteTotalVolume(): void {
    this.totalVolume = this.route.getTotalVolume();
  }

  public updateMap() {
    // Can we do this a better way?
    // Should some of this be contained in the map component?
    if (this.firstLoad && this.products.length) {
      this.firstLoad = false;
      this.paginatedProducts.forEach(product => {
        this.addMarkerForProduct(product);
      });

      //this.googleMap.zoomToMarkers();
    } else {
      const allMarkerIds = Object.keys(this.googleMap.markers);

      /*
       * TODO This currently just removes all markers and re-adds them due to
       * TODO  the map not repainting with new values on the markers
       * TODO  Will need to look into a better way to do this
       */

      allMarkerIds.forEach(markerId => {
        // This marker is not in the subset of products. Remove it.
        this.googleMap.markers[parseInt(markerId, 10)].setMap(null);
        delete this.googleMap.markers[parseInt(markerId, 10)];
      });
      this.paginatedProducts.forEach(product => {
        // We have a product without a marker.
        this.addMarkerForProduct(product);
        if (product.productId === this.selectedProductId) {
          this.setMarkerSelected(product.productId, true, product);
        }
      });
    }
  }

  public productSelectedFromList(selectedProduct: Product, id: number): void {
    this.updateActive(selectedProduct, id);
    if (selectedProduct) {
      const prevSelect = this.selectedProductId;

      GA.event<EventViewEntitySidebar>(
        this.$gtag,
        new EventViewEntitySidebar(
          selectedProduct.productId,
          this.getEntityApiWord(),
          selectedProduct?.entities
            ? selectedProduct?.entities[id]?.entityId ?? undefined
            : undefined
        )
      );

      if (
        prevSelect === selectedProduct.productId &&
        ((this.isApp(this.PRODUCT_TYPE_MILK) && id === -1) ||
          (!this.isApp(this.PRODUCT_TYPE_MILK) && this.entityIndex == id))
      ) {
        if (this.selected == true) {
          this.contentWidth = this.contentWidth + 400;
        }
        this.selected = false;
        ProductStore.updateCurrentEntityIndexState(-1);
        this.entityIndex = -1;
        this.selectedProductId = null;
      } else {
        if (this.selected == false) {
          this.contentWidth = this.contentWidth - 400;
        }
        this.selected = true;
        this.entityIndex = id == -1 ? 0 : id;

        ProductStore.updateCurrentEntityIndexState(id == -1 ? 0 : id);
        this.selectedProductId = selectedProduct.productId;
      }
      this.updateMap();
    }
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm.toString();
    GA.event<EventSearchCustomers>(
      this.$gtag,
      new EventSearchCustomers(searchTerm)
    );
    this.filterProducts();
  }

  private addMarkerForProduct(product: Product): void {
    this.googleMap.addMarker({
      lat: parseFloat(product.latitude),
      lon: parseFloat(product.longitude),
      tag: product.productId,
      markerVal: getAppMapTag(this.mapSettings?.mapTag, product),
      supplyNum: product.supplierNumber,
      isSelected: product.productId === this.selectedProductId,
      mapTagValue: this.mapSettings?.mapTag
    });
  }

  private setMarkerSelected(
    index: number,
    isSelected: boolean,
    product: Product | null
  ): void {
    if (
      this.googleMap &&
      this.googleMap.markers &&
      this.googleMap.markers[index]
    ) {
      this.googleMap.markers[index].setIcon(
        this.googleMap.getMarker({
          isSelected,
          markerVal: product
            ? getAppMapTag(this.mapSettings?.mapTag, product)
            : '-',
          mapTagValue: this.mapSettings?.mapTag
        })
      );
    }
    if (isSelected) {
      if (this.prevSelectedIndex != index) {
        this.prevSelectedIndex = index;
      }
    }
  }

  public toggleClusteringHandler(value: boolean) {
    // this.enableClustering = value;
    // this.reloadMarkers = !value;
  }

  public mapDidSelectMarkerHandler(productId: number): void {
    const prevSelect = this.selectedProductId;
    if (prevSelect) {
      this.setMarkerSelected(prevSelect, false, this.selectedProduct ?? null);
    }

    if (prevSelect === productId) {
      this.selected = false;
      this.active = '';
      this.selectedProductId = null;
    } else {
      this.selectedProductId = productId;
      ProductStore.updateCurrentEntityIndexState(
        this.mapSettings?.mapTag == 'age'
          ? this.selectedProduct?.maxAge?.index ?? 0
          : this.selectedProduct?.maxScore?.index ?? 0
      );
      this.entityIndex =
        this.mapSettings?.mapTag == 'age'
          ? this.selectedProduct?.maxAge?.index ?? 0
          : this.selectedProduct?.maxScore?.index ?? 0;
      this.selected = true;
      this.active = productId + '/' + this.entityIndex;
    }
    if (this.selectedProductId) {
      this.setMarkerSelected(
        this.selectedProductId,
        true,
        this.selectedProduct ?? null
      );
    }
  }

  public filterProducts(): void {
    let filtered = ProductStore.currentProducts;
    if (filtered) {
      this.offset = 0;
      this.filters.setMaxRanges(filtered);
      if (this.isRouteView) {
        filtered = filtered.filter(product => {
          const entityId = product.entityId ?? '';
          return (
            this.route.routePlanProducts.includes(product.productId) &&
            (this.route.routePlanEntities.includes(entityId) ||
              (this.isApp(this.PRODUCT_TYPE_MILK) &&
                this.route.routePlanEntities.includes(
                  `all${product.productId}`
                )))
          );
        });
      }

      filtered = this.filters.filterSearch(
        filtered,
        this.searchTerm,
        this.tableColumns
      );
      setHasNonFonterra(getHasNonFonterra(filtered));

      filtered = this.filters.filterProducts(filtered);
      this.checkFiltersUpdated();
      this.entities = filtered;
      this.products = formatAppProducts(filtered);

      this.updatePaginatedProducts();
    }
  }

  public updateSortBy(sorter: TableColumnFormatters) {
    this.updateSorting({
      sortBy: sorter,
      increasing:
        sorter.name == this.sortInfo.sortBy?.name
          ? !this.sortInfo.increasing
          : true
    });
  }

  public updateSortDesc(newVal: boolean) {
    this.increasing = !newVal;
  }

  public isSupply(product: Product) {
    let isSupply = false;
    if (product.entities && product.entities?.length > 0) {
      product.entities.forEach(vat => {
        if ((vat.metrics as MilkMetrics).state == 'supply') {
          isSupply = true;
        }
      });
      return isSupply;
    }
    return product.entities &&
      (product.entities[0].metrics as MilkMetrics).state == 'supply'
      ? true
      : false;
  }

  public dragMapHeight(dy: any) {
    const contain = document.getElementById('mapContainer');
    if (contain) {
      this.mapHeight = +(this.mapHeight - dy);
    }
  }

  public hideMap() {
    this.activeSearch = false;
    if (!this.collapseMap && this.mapHeight > 0) {
      this.collapseMap = true;
      this.prevMapHeight = this.mapHeight;
      this.mapHeight = 0;
    } else {
      this.collapseMap = false;
      if (this.prevMapHeight <= 0) {
        this.prevMapHeight = 500;
      }

      this.mapHeight = this.prevMapHeight;
    }
    this.$root.$emit('updateListSize');
    setObjectItem(`${this.getAppName(false)}HideMap`, this.collapseMap);
  }

  public applyCustomFilters(
    saveFilters: SavedAppFilters,
    filters: AppFilters,
    save: boolean
  ) {
    this.filters.filters = filters;
    trackEvent(`User applied filters to ${this.getAppName(false)} products`, {
      filters: JSON.stringify(filters)
    });
    GA.event<EventFilterEntities>(
      this.$gtag,
      new EventFilterEntities(filters, save)
    );
    if (save) {
      this.saveFilters(saveFilters);
    }
  }

  public openFiltersModal() {
    this.activeSearch = false;
    if (this.getAppName(false) == Constants.PRODUCT_TYPE_WATER) {
      this.$root.$emit(
        'bv::show::modal',
        `${returnOnEntityAccess('waterTank', 'waterMeter')}FiltersModal`,
        '#btnShow'
      );
    } else {
      this.$root.$emit(
        'bv::show::modal',
        `${this.getAppName(false)}FiltersModal`,
        '#btnShow'
      );
    }
  }

  public changeOffset(isPrevious: boolean, exactPage: number) {
    if (isPrevious == null) {
      this.offset = exactPage * this.perPageLimit;
    } else {
      this.offset += isPrevious ? -this.perPageLimit : this.perPageLimit;
    }
    this.updatePaginatedProducts();
  }

  public closeSidebar() {
    if (this.selectedProductId) {
      this.mapDidSelectMarkerHandler(this.selectedProductId);
    } else {
      this.selected = false;
      this.active = '';
      this.selectedProductId = null;
    }
  }

  public updateFilterByMap(value: boolean) {
    this.filterByMap = value;
  }

  public resetPage() {
    this.searchTerm = '';
  }

  public selectEntityById(entityId: number | null) {
    if (
      this.selectedProduct &&
      this.selectedProduct?.entities &&
      entityId != null
    ) {
      const index = this.selectedProduct?.entities.findIndex(
        entity => entity.entityId == entityId
      );
      if (index != -1) {
        this.entitySelected(index);
      }
    }
  }

  public entitySelected(entityIndex: number) {
    if (entityIndex == -1) {
      this.active = '';
    } else {
      const prevEntity = this.entities[this.entityIndex];
      const prevEntityType = this.getEntityApiWord();
      ProductStore.updateCurrentEntityIndexState(entityIndex);
      this.entityIndex = entityIndex;

      GA.event<EventSidebarChangeEntity>(
        this.$gtag,
        new EventSidebarChangeEntity(
          prevEntity?.entityId,
          prevEntityType,
          this.entities[this.entityIndex]?.entityId,
          this.getEntityApiWord()
        )
      );

      const productId = +this.active.split('/')[0];
      if (entityIndex == -1) {
        this.active = productId + '/0';
      } else {
        this.active = productId + '/' + entityIndex;
      }
    }
  }

  public updateActive(item: Product, entityIndex: number) {
    if (
      this.active != '' &&
      item.productId == +this.active.split('/')[0] &&
      ((this.isApp(this.PRODUCT_TYPE_MILK) && entityIndex === -1) ||
        (!this.isApp(this.PRODUCT_TYPE_MILK) &&
          this.entityIndex == entityIndex))
    ) {
      this.active = '';
    } else {
      if (entityIndex == -1) {
        this.active = item.productId + '/0';
      } else {
        this.active = item.productId + '/' + entityIndex;
      }
    }
  }

  public updateSorting(newSortInfo: SortingInfo) {
    trackEvent(`User updated ${this.getAppName(false)} products sorting`, {
      sortBy: newSortInfo.sortBy?.name ?? ''
    });
    this.sortInfo = newSortInfo;
    if ((newSortInfo.sortBy?.name ?? '') == 'alertsDefault') {
      this.sortInfo.increasing = false;
    }
    setObjectItem(`${this.getAppName(false)}SortBy`, {
      ...newSortInfo,
      sortBy: { name: newSortInfo.sortBy?.name ?? '' }
    });
    this.offset = 0;

    this.updatePaginatedProducts();
  }

  public clearRoute() {
    this.route.clear();
    this.products = [];
    this.updatePaginatedProducts();
    this.$root.$emit('routeUpdated');
  }

  public changePerPage(number: number) {
    if (this.perPageLimit != number) {
      trackEvent(`User changed number per page on ${this.getAppName(false)}`, {
        perPage: number.toString()
      });
    }

    this.perPageLimit = number;
    setUserItem(`${this.getAppName(false)}PerPage`, number.toString());
    this.offset = 0;

    this.updatePaginatedProducts();
  }

  public updateActiveSearch(value: boolean) {
    this.activeSearch = value;
  }
}
