
import FilterTextInput from '@/components/filters/FilterTextInput.vue';
import CustomField from '@/store/models/CustomField';
import { SavedWaterTankFilters, WaterFilters } from '@/store/models/Filters';
import CustomStore from '@/store/modules/CustomStore';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({ components: { FilterTextInput } })
export default class WaterTankFiltersModal extends Vue {
  @Prop({ type: Object }) selectedPropSiteFilters!: {
    field: CustomField;
    selection: string[];
  }[];
  @Prop({ type: Object }) selectedPropTankFilters!: {
    field: CustomField;
    selection: string[];
  }[];
  @Prop({ required: true, type: Object }) propFilters!: WaterFilters;
  @Prop({ required: true, type: Number }) maxPercent!: number;
  @Prop({ required: true, type: Number }) width!: number;

  public selectedSiteFilters: {
    field: CustomField;
    selection: string[];
  }[] = [];
  public selectedTankFilters: {
    field: CustomField;
    selection: string[];
  }[] = [];
  public selectedFilters: WaterFilters = {
    volumeRange: [0, 0],
    percentRange: [0, 0],
    capacityRange: [0, 0],
    currentUsageRange: [0, 0],
    usageTodayRange: [0, 0],
    usageYesterdayRange: [0, 0],
    customSiteFilters: [],
    customEntityFilters: []
  };
  public updated = false;

  mounted() {
    if (this.propFilters) {
      this.selectedFilters = JSON.parse(JSON.stringify(this.propFilters));
    }
    if (this.selectedPropSiteFilters) {
      this.selectedFilters.customSiteFilters = this.selectedPropSiteFilters;
    }
    if (this.selectedPropTankFilters) {
      this.selectedFilters.customEntityFilters = this.selectedPropTankFilters;
    }
  }

  @Watch('selectedPropSiteFilters')
  sitePropFilterChanged() {
    if (this.selectedPropSiteFilters) {
      this.selectedFilters.customSiteFilters = this.selectedPropSiteFilters;
    }
  }

  @Watch('selectedPropTankFilters')
  tankPropFilterChanged() {
    if (this.selectedPropTankFilters) {
      this.selectedFilters.customEntityFilters = this.selectedPropTankFilters;
    }
  }

  @Watch('propFilters', { deep: true })
  filtersUpdated() {
    if (this.propFilters) {
      this.selectedFilters = this.propFilters;
    }
  }

  @Watch('maxPercent')
  maxVolumeLoaded(value: number, oldValue: number) {
    if (this.selectedFilters.percentRange[1] == oldValue) {
      this.selectedFilters.percentRange[1] = value;
    } else if (this.selectedFilters.percentRange[1] == 0) {
      this.selectedFilters.percentRange[1] = this.maxPercent;
    }
  }

  get listSiteCustomFields() {
    const filtered = JSON.parse(
      JSON.stringify(CustomStore.customFields)
    )?.filter(
      (item: CustomField) => item.type == 'List Entry' && item.filterable
    );
    return filtered.map((item: CustomField) => {
      return {
        ...item,
        listOptions: item.listOptions?.sort((a, b) => {
          if (a != null && b != null) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          } else if (a != null && b == null) {
            return -1;
          } else {
            return -1;
          }
        })
      };
    });
  }
  get listTankCustomFields() {
    const filtered = JSON.parse(
      JSON.stringify(CustomStore.customEntityFields)
    )?.filter(
      (item: CustomField) => item.type == 'List Entry' && item.filterable
    );
    return filtered.map((item: CustomField) => {
      return {
        ...item,
        listOptions: item.listOptions?.sort((a, b) => {
          if (a != null && b != null) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          } else if (a != null && b == null) {
            return -1;
          } else {
            return -1;
          }
        })
      };
    });
  }

  public isSelected(field: CustomField, selection: string, level: string) {
    let includes = false;
    if (level == 'site') {
      this.selectedFilters.customSiteFilters.forEach(filter => {
        if (
          filter.field.name == field.name &&
          filter.selection.includes(selection)
        ) {
          includes = true;
        }
      });
    } else if (level == 'tank') {
      this.selectedFilters.customEntityFilters.forEach(filter => {
        if (
          filter.field.name == field.name &&
          filter.selection.includes(selection)
        ) {
          includes = true;
        }
      });
    }
    return includes;
  }

  public addFilter(field: CustomField, selection: string, level: string) {
    this.updated = true;
    let includes = false;
    let selectionAlreadyInField = false;
    if (level == 'site') {
      this.selectedFilters.customSiteFilters.forEach(filter => {
        if (filter.field.name == field.name) {
          selectionAlreadyInField = true;
          if (filter.selection.includes(selection)) {
            includes = true;
          }
        }
      });
      if (!includes) {
        if (selectionAlreadyInField) {
          this.selectedFilters.customSiteFilters
            .find(filter => filter.field.name == field.name)
            ?.selection.push(selection);
        } else {
          this.selectedFilters.customSiteFilters.push({
            field: field,
            selection: [selection]
          });
        }
      } else {
        const selectedFilter = this.selectedFilters.customSiteFilters.find(
          filter => filter.field.name == field.name
        );
        if (
          selectedFilter?.selection &&
          selectedFilter?.selection.length == 1
        ) {
          this.selectedFilters.customSiteFilters = this.selectedFilters.customSiteFilters.filter(
            filter => filter.field.name != field.name
          );
        } else if (selectedFilter?.selection) {
          selectedFilter.selection = selectedFilter.selection.filter(
            select => selection != select
          );
        }
      }
    } else if (level == 'tank') {
      this.selectedFilters.customEntityFilters.forEach(filter => {
        if (filter.field.name == field.name) {
          selectionAlreadyInField = true;
          if (filter.selection.includes(selection)) {
            includes = true;
          }
        }
      });

      if (!includes) {
        if (selectionAlreadyInField) {
          this.selectedFilters.customEntityFilters
            .find(filter => filter.field.name == field.name)
            ?.selection.push(selection);
        } else {
          this.selectedFilters.customEntityFilters.push({
            field: field,
            selection: [selection]
          });
        }
      } else {
        const selectedFilter = this.selectedFilters.customEntityFilters.find(
          filter => filter.field.name == field.name
        );
        if (
          selectedFilter?.selection &&
          selectedFilter?.selection.length == 1
        ) {
          this.selectedFilters.customEntityFilters = this.selectedFilters.customEntityFilters.filter(
            filter => filter.field.name != field.name
          );
        } else if (selectedFilter?.selection) {
          selectedFilter.selection = selectedFilter.selection.filter(
            select => selection != select
          );
        }
      }
    }
  }

  public applyFilters(applyAndSave = false) {
    const alteredFilters: SavedWaterTankFilters = JSON.parse(
      JSON.stringify(this.selectedFilters)
    );
    if (this.selectedFilters.percentRange[1] == this.maxPercent) {
      alteredFilters.percentRange[1] = null;
    }
    this.$emit(
      'waterTankFiltersUpdated',
      alteredFilters,
      this.selectedFilters,
      applyAndSave
    );
    this.closeFiltersModal();
  }
  public clearCustomFilters() {
    this.selectedFilters = {
      volumeRange: [0, 0],
      percentRange: [0, this.maxPercent],
      capacityRange: [0, 0],
      currentUsageRange: [0, 0],
      usageTodayRange: [0, 0],
      usageYesterdayRange: [0, 0],
      customSiteFilters: [],
      customEntityFilters: []
    };
    this.$emit('clearFilters');
    this.applyFilters(false);
    this.closeFiltersModal();
  }

  public closeFiltersModal() {
    this.$root.$emit('bv::hide::modal', 'waterTankFiltersModal', '#btnHide');
  }
}
