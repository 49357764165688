var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BModal',{ref:"modal",staticClass:"d-flex",attrs:{"id":"csvModal","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title w-100 text-center",staticStyle:{"margin-right":"-44px"},attrs:{"id":"csvModal___BV_modal_title_"}},[_vm._v(" "+_vm._s(_vm.toSentenceCase(_vm.reportType))+" Report ")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" × ")])]},proxy:true}])},[(!_vm.submitted && !_vm.loading)?_c('div',[_c('div',{staticClass:"mx-auto px-2"},[(_vm.reportType == 'collections' || _vm.reportType == 'milkings')?_c('FromToDatePicker',{staticClass:"mb-2",attrs:{"collectionsDate":_vm.collectionsDate}}):_vm._e(),_c('label',[_vm._v(" "+_vm._s(_vm.route ? 'Selected' : 'Filtered')+" products or all products? "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":`${
                _vm.route
                  ? 'Selected products, also referred to as your selected route, are all the products that have been checked and added to route.'
                  : 'Filtered products are the products displayed in the list on the dashboard, which are filtered by the map bounds and applied filters.'
              } All products are all of the products you have access to.`}},[_c('BIcon',{staticClass:"ml-1",attrs:{"variant":"mediumGrey","icon":"question-circle"}})],1)]),_c('b-form-group',{staticClass:"mx-auto d-flex",scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-radio-group',{staticClass:"radioButton mx-auto d-flex",attrs:{"id":"product","options":[
              {
                text: _vm.route ? 'Selected' : 'Filtered',
                value: false
              },
              { text: 'All', value: true }
            ],"aria-describedby":ariaDescribedby,"name":"product","buttons":"","button-variant":"outline-dark"},model:{value:(_vm.allProducts),callback:function ($$v) {_vm.allProducts=$$v},expression:"allProducts"}})]}}],null,false,2321243853)}),(_vm.productType == 'milk' && _vm.reportType != 'milkings')?_c('label',[_vm._v(" "+_vm._s(_vm.getEntityName(true))+" level or product level? "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":`${_vm.getEntityName(
                true
              )} level will generate an entry for each individual ${_vm.getEntityName(
                false
              )}. Product level collates the data of multi-${_vm.getEntityName(
                false
              )} products into one entry for each product.`}},[_c('BIcon',{staticClass:"ml-1",attrs:{"variant":"mediumGrey","icon":"question-circle"}})],1)]):_vm._e(),(_vm.productType == 'milk' && _vm.reportType != 'milkings')?_c('b-form-group',{staticClass:"mx-auto d-flex",scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-radio-group',{staticClass:"radioButton mx-auto",attrs:{"id":"productType-radio","options":[
              { text: `${_vm.getEntityName(true)} Level`, value: false },
              { text: 'Product Level', value: true }
            ],"aria-describedby":ariaDescribedby,"name":"productType-radio","buttons":"","button-variant":"outline-dark"},model:{value:(_vm.productLevel),callback:function ($$v) {_vm.productLevel=$$v},expression:"productLevel"}})]}}],null,false,1906726812)}):_vm._e(),_c('p',{staticClass:"mt-2 mb-1"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.editColumns",modifiers:{"editColumns":true}}],staticClass:"text-mediumGrey"},[_c('BIcon',{attrs:{"icon":"pencil"}}),_vm._v(" Edit Report Columns "),_c('BIcon',{staticClass:"when-closed",attrs:{"icon":"chevron-down"}}),_c('BIcon',{staticClass:"when-opened",attrs:{"icon":"chevron-up"}})],1)]),_c('BCollapse',{staticClass:"inner-shadow p-2",attrs:{"id":"editColumns"}},[(_vm.defaultFields.length > 0)?_c('div',[_c('BRow',_vm._l((_vm.defaultFields),function(field){return _c('BCol',{key:field,attrs:{"cols":"6","title":_vm.toSentenceCase(field)}},[_c('BFormCheckbox',{attrs:{"id":field,"value":field},model:{value:(_vm.selectedFields),callback:function ($$v) {_vm.selectedFields=$$v},expression:"selectedFields"}},[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.toSentenceCase(field))+" ")])])],1)}),1),(
                !_vm.isApp(_vm.PRODUCT_TYPE_MILK) &&
                  (_vm.customFields || _vm.customEntityFields)
              )?_c('p',{staticClass:"pb-1 mb-0 pt-2"},[_vm._v(" Custom Fields ")]):_vm._e(),(
                !_vm.isApp(_vm.PRODUCT_TYPE_MILK) &&
                  (_vm.customFields || _vm.customEntityFields)
              )?_c('BRow',_vm._l(([
                  ..._vm.customFields,
                  ..._vm.customEntityFields
                ]),function(field,index){return _c('BCol',{key:`${field.name}${index}`,attrs:{"cols":"6","title":field.label}},[_c('BFormCheckbox',{attrs:{"id":`${field.name}${index}`,"value":field.label},model:{value:(_vm.selectedCustomFields),callback:function ($$v) {_vm.selectedCustomFields=$$v},expression:"selectedCustomFields"}},[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(field.label)+" ")])])],1)}),1):_vm._e(),(
                _vm.cookieSelection.length != 0 || _vm.cookieCustomSelection.length != 0
              )?_c('p',{staticClass:"mt-2 mb-1"},[_c('a',{on:{"click":_vm.restoreFromCookie}},[_vm._v(" Restore selection from last download "),_c('BIcon',{attrs:{"icon":"arrow-repeat"}})],1)]):_vm._e()],1):_c('p',{staticClass:"mb-1"},[_vm._v(" Report type not selected. Please select the type of report using the buttons above. ")])]),_c('hr')],1)]):_vm._e(),(!_vm.submitted && !_vm.loading)?_c('BButton',{staticClass:"d-flex mx-auto",attrs:{"disabled":_vm.allProducts == null ||
          (_vm.productType == 'milk' &&
            _vm.productLevel == null &&
            this.reportType != 'milkings') ||
          ((_vm.reportType == 'collections' || _vm.reportType == 'milkings') &&
            (_vm.collectionsDate.from == '' ||
              _vm.collectionsDate.to == '' ||
              !_vm.isFromBeforeTo)),"variant":"grey"},on:{"click":function($event){return _vm.generateExport()}}},[_vm._v(" Generate "+_vm._s(_vm.allProducts != null ? _vm.allProducts ? 'All ' : _vm.route ? 'Selected ' : 'Filtered ' : '')+_vm._s(_vm.productLevel != null || _vm.productType == 'fuel' ? _vm.productLevel || _vm.productType == 'fuel' ? 'Products ' : `${_vm.getPluralEntityName(true)} ` : '')+" Report "),_c('BIcon',{staticClass:"ml-1",attrs:{"icon":"download"}})],1):_vm._e(),(_vm.submitted && _vm.loading && !_vm.loaded)?_c('div',[_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"mt-4 text-center",attrs:{"variant":"secondary","id":"spinner3"}})],1),_c('p',{staticClass:"text-center mt-2",attrs:{"variant":"secondary"}},[_vm._v(" Loading data... ")])]):_vm._e(),(_vm.loaded && _vm.sheets[0].data.length == 0)?_c('p',{staticClass:"text-center"},[_vm._v(" No products to download ")]):_vm._e(),(_vm.sheets[0].data.length > 0 && _vm.loaded)?_c('xlsx-workbook',{staticClass:"d-flex mx-auto"},[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":sheet.data,"sheet-name":sheet.name}})}),_c('xlsx-download',{staticClass:"d-flex mx-auto",attrs:{"filename":_vm.filename}},[_c('BButton',{staticClass:"mx-auto",attrs:{"variant":"grey"}},[_vm._v(" Download "+_vm._s(_vm.allProducts != null ? _vm.allProducts ? 'All ' : _vm.route ? 'Selected ' : 'Filtered ' : '')+_vm._s(_vm.productLevel != null || _vm.productType == 'fuel' ? _vm.productLevel || _vm.productType == 'fuel' ? 'Products ' : `${_vm.getPluralEntityName(true)} ` : '')+" Report "),_c('BIcon',{staticClass:"ml-1",attrs:{"icon":"download"}})],1)],1)],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }