import { GAEvent } from '@/services/ga/events/GAEvent';

export class EventSearchCustomers extends GAEvent {
  public eventName = 'search-customers';
  public searchTerm?: string;

  constructor(searchTerm?: string) {
    super();
    this.searchTerm = searchTerm;
  }
}
