export async function runInBatches(
  items: any[],
  f: (item: any) => any,
  batchSize = 10
) {
  async function runBatch(items: any[], f: (item: any) => void) {
    return await Promise.all(items.map(item => f(item))).then(data => {
      return data;
    });
  }
  const result: any[] = [];
  let batch: any[] = [];
  for (const item of items) {
    batch.push(item);
    if (batch.length >= batchSize) {
      await runBatch(batch, f).then(data => {
        data.forEach(prod => {
          result.push(prod);
        });
      });
      batch = [];
    }
  }
  if (batch.length) {
    await runBatch(batch, f).then(data => {
      data.forEach(prod => {
        result.push(prod);
      });
    });
  }
  return result;
}
