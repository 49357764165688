
import { GA } from '@/services/ga/GoogleAnalytics';

interface Sheet {
  name: string;
  data: Product[];
  bookType: 'xlsx' | 'csv';
}

import FilterDropdown from '@/components/filters/FilterDropdown.vue';
import CollectionsCSVModal from '@/components/modal/CollectionsCSVModal.vue';
import CSVModal from '@/components/modal/CSVModal.vue';
import CurrentStateCSVModal from '@/components/modal/CurrentStateCSVModal.vue';
import {
  FetchCollectionsDataByProduct,
  FetchCollectionsDataByVat,
  FetchCurrentStateDataByProduct,
  FetchCurrentStateDataByVat,
  FetchMilkingsData
} from '@/services/api/ExportsApi';
import { trackEvent } from '@/services/Mixpanel';
import CustomField from '@/store/models/CustomField';
import { FeedMetrics, FuelMetricsTank, Product } from '@/store/models/Product';
import CustomStore from '@/store/modules/CustomStore';
import User from '@/store/modules/User';
import { runInBatches } from '@/utils/BatchRequests';
import { toHoursMinutes } from '@/utils/DateUtils';
import { alterFeedExportAmount, isWeight } from '@/utils/FeedUtils';
import { getSentenceCaseObject } from '@/utils/FormattingUtils';
import {
  formatFuelDaysToEmpty,
  formatMilkScoreForExport
} from '@/utils/TableFormatters';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Inject, Prop, PropSync } from 'vue-property-decorator';
import { XlsxDownload, XlsxSheet, XlsxWorkbook } from 'vue-xlsx';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import { EventSearchCustomers } from '@/services/ga/events/EventSearchCustomers';
import { EventDownloadCSV } from '@/services/ga/events/EventDownloadCSV';
import { PermissionName } from '@/store/models/PermissionName';
import { PermissionService } from '@/services/PermissionService';

@Component({
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    FilterDropdown,
    CollectionsCSVModal,
    CurrentStateCSVModal,
    CSVModal
  }
})
export default class XlsxDownloader extends mixins(AppName, ConstantsMixin) {
  @PropSync('items') products!: Product[];
  @PropSync('allItems') allProducts!: Product[];
  @Prop() view!: string;
  @Prop() route!: boolean;
  @Prop() width150!: boolean;

  @Inject('permissionService')
  private permissionService!: PermissionService;

  public refreshValues = false;
  public newSheets: Sheet[] = [{ name: '', data: [], bookType: 'csv' }];
  public reportType = 'currentState';
  public loaded = false;
  public downloadAllProducts = false;
  public collectionsDate = { from: '', to: '' };
  public error = false;
  public filename = 'Data_Export.csv';

  public get canGenerateReports(): boolean {
    return this.permissionService.canGenerateReports;
  }

  get customFields() {
    return CustomStore.customFields ?? [];
  }

  get customEntityFields() {
    return CustomStore.customEntityFields ?? [];
  }

  public generateReport(
    allProducts: boolean,
    productLevel: boolean,
    collectionsDate: { from: string; to: string },
    selectedFields: string[]
  ) {
    const prods = allProducts ? this.allProducts : this.products;

    GA.event<EventDownloadCSV>(
      this.$gtag,
      new EventDownloadCSV(this.reportType, {
        productLevel,
        collectionsDate,
        selectedFields
      })
    );

    if (this.reportType == 'collections') {
      this.collectionsDate = collectionsDate;
      this.generateCollections(prods, productLevel, selectedFields);
    } else if (this.reportType == 'milkings') {
      this.collectionsDate = collectionsDate;
      this.generateMilkings(prods, productLevel, selectedFields);
    } else {
      if (this.view == 'milk') {
        productLevel
          ? this.generateCurrentStateProductLevel(prods, selectedFields)
          : this.generateCurrentStateVatLevel(prods, selectedFields);
      } else {
        this.generateFuelCurrentState(prods, selectedFields);
      }
    }
  }

  public generateFuelCurrentState(prods: any[], selectedFields: string[]) {
    this.loaded = false;
    runInBatches(
      this.sortCurrentState(prods),
      async (product): Promise<Product> => {
        // const singleLayer = this.getSingleLayerProduct(
        //   product
        // );
        const f: { [key: string]: string } = {};
        if (product.customFieldData) {
          this.customFields.forEach((field: CustomField) => {
            f[field.label] = product.customFieldData
              ? product.customFieldData[field.name] ?? ''
              : '';
          });
        }
        if (product.entityCustomFieldData) {
          this.customEntityFields.forEach((field: CustomField) => {
            f[field.label] = product.entityCustomFieldData
              ? product.entityCustomFieldData[field.name] ?? ''
              : '';
          });
        }
        let feedAlterations: { [key: string]: string | number } = {};
        if (this.isApp(this.PRODUCT_TYPE_FEED)) {
          feedAlterations = {
            weight: alterFeedExportAmount(
              (product.metrics as FeedMetrics)?.volume
            ),
            nominalTonnage: alterFeedExportAmount(
              (product.metrics as FeedMetrics)?.nominalVolume
            ),
            dailyUse: alterFeedExportAmount(
              (product.metrics as FeedMetrics)?.dailyUse
            ),
            availableCapacity: alterFeedExportAmount(
              (product.metrics as FeedMetrics)?.fillVolume
            ),
            lastRefill:
              isWeight() && (product.metrics as FeedMetrics)?.lastDeliveryWeight
                ? alterFeedExportAmount(
                    (product.metrics as FeedMetrics)?.lastDeliveryWeight ?? 0
                  )
                : product.metrics?.lastDeliveryVolume
                ? alterFeedExportAmount(product.metrics?.lastDeliveryVolume)
                : '',
            lastRefillDate: this.alterTimestamps(
              (product.metrics as FuelMetricsTank)?.lastDeliveryTimestamp
            )
          };
        }

        return getSentenceCaseObject(
          this.filterTableFields(selectedFields, {
            ...product,
            ...product.metrics,
            ...f,
            id: product.partnerDefinedId ?? '',
            lastDeliveryDate: this.alterTimestamps(
              (product.metrics as FuelMetricsTank)?.lastDeliveryTimestamp
            ),
            estimatedEmptyDate: this.alterTimestamps(
              (product.metrics as FuelMetricsTank)?.estimatedEmptyTimestamp
            ),
            recordedAt: this.alterTimestamps(
              (product.metrics as FuelMetricsTank)?.volumeTs ?? 0
            ),
            daysTillEmpty: product.metrics
              ? formatFuelDaysToEmpty(product.metrics.estimatedEmptyTimestamp)
              : null,
            ullage: (product.metrics as FuelMetricsTank)?.fillVolume,
            levnoInternalEntityReference: product.entityId,
            levnoInternalProductReference: product.productId,
            ...feedAlterations
          })
        );
      }
    ).then(data => {
      this.newSheets[0].data = data;

      trackEvent(
        `User loaded fuel csv file - ${
          this.downloadAllProducts ? 'all products' : 'filtered products'
        }`
      );
      this.loaded = true;
    });
  }

  public async generateCurrentStateVatLevel(
    prods: any[],
    selectedFields: string[]
  ) {
    this.error = false;
    this.loaded = false;
    const productIds = Array.from(
      new Set(prods.map(product => product.productId))
    );

    const currentState = await FetchCurrentStateDataByVat({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      productIds: productIds
    });

    if (currentState) {
      currentState.sort((a, b) => {
        return dayjs(a.supplyNumber).isAfter(dayjs(b.supplyNumber)) ? 1 : -1;
      });
      this.newSheets[0].data = currentState.map((product: any) => {
        return getSentenceCaseObject(
          this.filterTableFields(selectedFields, {
            ...product,
            lastMilking: this.alterTimestamps(product.lastMilking),
            secondLastMilking: this.alterTimestamps(product.secondLastMilking),
            lastCollection: this.alterTimestamps(product.lastCollection),
            supplierNumber: product.supplyNumber,
            milkScore: formatMilkScoreForExport(
              product.milkScore,
              product.complianceMethod
            ),
            milkAge: toHoursMinutes(product.milkAge),
            complianceMethod: product.complianceMethod,
            levnoInternalEntityReference: product.vatId,
            levnoInternalProductReference: product.productId
          })
        );
      });

      trackEvent(
        `User loaded current state vat report - ${
          this.downloadAllProducts ? 'all products' : 'filtered products'
        }`
      );
    } else {
      this.error = true;
    }
    this.loaded = true;
  }

  public async generateCurrentStateProductLevel(
    prods: any[],
    selectedFields: string[]
  ) {
    this.error = false;
    this.loaded = false;
    const productIds = Array.from(
      new Set(prods.map(product => product.productId))
    );

    const currentState = await FetchCurrentStateDataByProduct({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      productIds: productIds
    });

    if (currentState) {
      currentState.sort((a, b) => {
        return dayjs(a.supplyNumber).isAfter(dayjs(b.supplyNumber)) ? 1 : -1;
      });
      this.newSheets[0].data = currentState.map((product: any) =>
        getSentenceCaseObject(
          this.filterTableFields(selectedFields, {
            ...product,
            lastMilking: this.alterDateTime(product.lastMilking),
            complianceMethod: product.complianceMethod,
            supplierNumber: product.supplyNumber,
            milkScore: formatMilkScoreForExport(
              product.milkScore,
              product.complianceMethod
            ),
            levnoInternalProductReference: product.productId
          })
        )
      );
      trackEvent(
        `User loaded current state product report - ${
          this.downloadAllProducts ? 'all products' : 'filtered products'
        }`
      );
    } else {
      this.error = true;
    }
    this.loaded = true;
  }

  public async generateCollections(
    prods: any[],
    productLevel: boolean,
    selectedFields: string[]
  ) {
    this.reportType = 'collections';
    this.error = false;
    const startTs = dayjs(this.collectionsDate.from).unix();
    const endTs = dayjs(this.collectionsDate.to)
      .hour(23)
      .minute(59)
      .unix();
    const productIds = Array.from(
      new Set(prods.map(product => product.productId))
    );

    const collections = productLevel
      ? await FetchCollectionsDataByProduct({
          partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
          startTs: startTs, //1581853672,  //
          endTs: endTs, //1592688263,  //
          productIds: productIds //[1079223, 1077888]  //
        })
      : await FetchCollectionsDataByVat({
          partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
          startTs: startTs, //1591012800, //
          endTs: endTs, //1592740740, //
          productIds: productIds //[1079223, 1077888] //
        });

    if (collections) {
      let singleLayer: any[] = collections.sort((a, b) => {
        return dayjs(a.collectionDateTime).isAfter(dayjs(b.collectionDateTime))
          ? -1
          : 1;
      });
      singleLayer = collections.map(coll =>
        getSentenceCaseObject(
          this.filterTableFields(selectedFields, {
            ...coll,
            collectionDateTime: this.alterDateTime(coll.collectionDateTime),
            ageAtCollection: toHoursMinutes(coll.ageAtCollection),
            scoreAtCollection: formatMilkScoreForExport(
              +coll.scoreAtCollection,
              coll.complianceMethod
            ),
            supplierNumber: coll.supplyNumber,
            complianceMethod: coll.complianceMethod
          })
        )
      );

      this.newSheets[0].data = singleLayer;
      trackEvent(
        `User loaded collections ${productLevel ? 'product' : 'vat'} report - ${
          this.downloadAllProducts ? 'all products' : 'filtered products'
        }`
      );
    } else {
      this.error = true;
    }

    trackEvent('User loaded collections csv file');
    this.loaded = true;
  }

  public async generateMilkings(
    prods: any[],
    productLevel: boolean,
    selectedFields: string[]
  ) {
    this.reportType = 'milkings';
    this.error = false;
    const startTs = dayjs(this.collectionsDate.from).unix();
    const endTs = dayjs(this.collectionsDate.to)
      .hour(23)
      .minute(59)
      .unix();
    const productIds = Array.from(
      new Set(prods.map(product => product.productId))
    );

    const milkings = await FetchMilkingsData({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      startTs: startTs, //1581853672,  //
      endTs: endTs, //1592688263,  //
      productIds: productIds //[1079223, 1077888]  //
    });

    if (milkings) {
      let singleLayer: any[] = milkings.sort((a, b) => {
        return dayjs(a.milkingFinishDateTime).isAfter(
          dayjs(b.milkingFinishDateTime)
        )
          ? -1
          : 1;
      });
      singleLayer = milkings.map(coll =>
        getSentenceCaseObject(
          this.filterTableFields(selectedFields, {
            ...coll,
            milkingStartDateTime: this.alterDateTime(coll.milkingStartDateTime),
            milkingFinishDateTime: this.alterDateTime(
              coll.milkingFinishDateTime
            ),
            milkingStartTemp:
              coll.milkingStartTemp == null
                ? ''
                : Math.round(+coll.milkingStartTemp * 10) / 10,
            milkingStartVolume:
              coll.milkingStartVolume == null
                ? ''
                : coll.milkingType == 'first'
                ? 0
                : Math.round(+coll.milkingStartVolume),
            milkingFinishTemp:
              coll.milkingFinishTemp == null
                ? ''
                : Math.round(+coll.milkingFinishTemp * 10) / 10,
            milkingFinishVolume:
              coll.milkingFinishVolume == null
                ? ''
                : Math.round(+coll.milkingFinishVolume),
            milkingTempSixHoursAfterStart:
              coll.milkingTempSixHoursAfterStart == null
                ? ''
                : Math.round(+coll.milkingTempSixHoursAfterStart),
            milkingTempTwoHoursAfterFinish:
              coll.milkingTempTwoHoursAfterFinish == null
                ? ''
                : Math.round(+coll.milkingTempTwoHoursAfterFinish),
            supplierNumber: coll.supplyNumber
          })
        )
      );

      this.newSheets[0].data = singleLayer;
      trackEvent(
        `User loaded milkings ${productLevel ? 'product' : 'vat'} report - ${
          this.downloadAllProducts ? 'all products' : 'filtered products'
        }`
      );
    } else {
      this.error = true;
    }

    trackEvent('User loaded collections csv file');
    this.loaded = true;
  }

  public getSingleLayerProduct(product: Product): any {
    //Still using the old gross method of generating fuel products
    const singleLayer: any = {
      ...product,
      ...product.metrics,
      ...product.customFieldData,
      ...product.entityCustomFieldData,
      lastDeliveryDate: this.alterTimestamps(
        (product.metrics as FuelMetricsTank)?.lastDeliveryTimestamp
      ),
      estimatedEmptyDate: this.alterTimestamps(
        (product.metrics as FuelMetricsTank)?.estimatedEmptyTimestamp
      ),
      recordedAt: this.alterTimestamps(
        (product.metrics as FuelMetricsTank)?.volumeTs ?? 0
      ),
      ullage: (product.metrics as FuelMetricsTank)?.fillVolume
    };
    delete singleLayer.metrics;
    delete singleLayer.entities;
    delete singleLayer.maxVolume;
    delete singleLayer.minVolume;
    delete singleLayer.lastDelivery;
    delete singleLayer.fillVolumeTs;
    delete singleLayer.nominalVolumeTs;
    delete singleLayer.lastDeliveryDateTs;
    delete singleLayer.estimatedEmptyDateTs;
    delete singleLayer.dailyUseTs;
    delete singleLayer.percentFullTs;
    delete singleLayer.fuelTypeTs;
    delete singleLayer.maxVolumeTs;
    delete singleLayer.minVolumeTs;
    delete singleLayer.lastDeliveryTs;
    delete singleLayer.estimatedEmptyTs;
    delete singleLayer.calculatedAt;
    delete singleLayer.entityCustomSettingsData;
    delete singleLayer.customSettingsData;
    delete singleLayer.entityCustomFieldData;
    delete singleLayer.customFieldData;
    delete singleLayer.fillVolume;

    return singleLayer;
  }

  public alterTimestamps(timestamp: number) {
    if (timestamp && dayjs.unix(timestamp).isValid()) {
      return dayjs.unix(timestamp).format('DD/MM/YYYY hh:mm:ssa');
    } else {
      return '';
    }
  }

  public alterDateTime(dateTime: string | number | undefined) {
    if (dayjs(dateTime).isValid()) {
      return dayjs(dateTime).format('DD/MM/YYYY hh:mm:ssa');
    } else {
      return '';
    }
  }

  public alterTimeDate(dateTime: string | number | undefined) {
    if (dayjs(dateTime).isValid()) {
      return dayjs(dateTime).format('hh:mm:ssa DD/MM/YYYY');
    } else {
      return '';
    }
  }

  public filterTableFields(allowed: string[], object: any) {
    const filtered = Object.keys(object)
      .filter(key => allowed.includes(key))
      .sort((a, b) => allowed.indexOf(a) - allowed.indexOf(b))
      .reduce((obj: any, key: any) => {
        obj[key] = object[key];
        return obj;
      }, {});
    return filtered;
  }

  public openNewModal(reportType: string) {
    this.loaded = false;
    this.refreshValues = !this.refreshValues;
    this.reportType = reportType;
    this.newSheets[0].data = [];
    this.$root.$emit('bv::show::modal', 'csvModal', '#btnShow');
  }

  public sortCurrentState(products: Product[]) {
    return products.sort((a: Product, b: Product) => {
      if (a.productId == b.productId) {
        if (a.entityId && b.entityId) {
          return a.entityId > b.entityId ? 1 : -1;
        } else {
          return 0;
        }
      }
      return a.productId > b.productId ? 1 : -1;
    });
  }
}
