import HttpClient from '@/services/api/ApiBase';
import CollectionsRequest from './models/requests/CollectionsRequest';
import Collection from './models/Collection';
import CurrentStateExportRequest from './models/requests/CurrentStateExportRequest';
import CurrentStateExport from './models/CurrentStateExport';
import CurrentStateVatExport from './models/CurrentStateVatExport';
import Milking from './models/Milking';

export const FetchCollectionsDataByProduct = async (
  params: CollectionsRequest
): Promise<Collection[] | null> => {
  try {
    const collections = await HttpClient.post(
      `/partner/${params.partnerId}/export/collections?startTs=${params.startTs}&endTs=${params.endTs}`,
      { productIds: params.productIds }
    );
    return collections.data.data;
  } catch {
    return null;
  }
};

export const FetchCollectionsDataByVat = async (
  params: CollectionsRequest
): Promise<Collection[] | null> => {
  try {
    const collections = await HttpClient.post(
      `/partner/${params.partnerId}/export/collectionsByVat?startTs=${params.startTs}&endTs=${params.endTs}`,
      { productIds: params.productIds }
    );
    return collections.data.data;
  } catch {
    return null;
  }
};

export const FetchMilkingsData = async (
  params: CollectionsRequest
): Promise<Milking[] | null> => {
  try {
    const collections = await HttpClient.post(
      `/partner/${params.partnerId}/export/milkings?startTs=${params.startTs}&endTs=${params.endTs}`,
      { productIds: params.productIds }
    );
    return collections.data.data;
  } catch {
    return null;
  }
};

export const FetchCurrentStateDataByProduct = async (
  params: CurrentStateExportRequest
): Promise<CurrentStateExport[] | null> => {
  try {
    const currentStates = await HttpClient.post(
      `/partner/${params.partnerId}/export/currentState`,
      { productIds: params.productIds }
    );
    return currentStates.data.data;
  } catch {
    return null;
  }
};

export const FetchCurrentStateDataByVat = async (
  params: CurrentStateExportRequest
): Promise<CurrentStateVatExport[] | null> => {
  try {
    const currentStates = await HttpClient.post(
      `/partner/${params.partnerId}/export/currentStateByVat`,
      { productIds: params.productIds }
    );
    return currentStates.data.data;
  } catch {
    return null;
  }
};
