
interface Sheet {
  name: string;
  data: Product[];
  bookType: 'xlsx' | 'csv';
}
import { Product } from '@/store/models/Product';
import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { XlsxDownload, XlsxSheet, XlsxWorkbook } from 'vue-xlsx';

@Component({
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  }
})
export default class CollectionsCSVModal extends Vue {
  @Prop() loaded!: boolean;
  @Prop() sheets!: Sheet[];
  @Prop() filename!: string;
  @Prop() error!: boolean;
  @Prop() collectionsDate!: { from: string; to: string };
  @Prop() collectionsDownload!: boolean;
  public max = dayjs().format('YYYY-MM-DD');

  get isFromBeforeTo() {
    return (
      this.collectionsDate.from == this.collectionsDate.to ||
      dayjs(this.collectionsDate.from).isBefore(dayjs(this.collectionsDate.to))
    );
  }

  public downloadCollections() {
    this.$emit('downloadCollections');
  }

  public closeCollectionsModal() {
    this.$root.$emit('bv::hide::modal', 'downloadCollections', '#btnHide');
  }
}
