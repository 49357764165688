import { GAEvent } from '@/services/ga/events/GAEvent';

export class EventDownloadCSV extends GAEvent {
  public eventName = 'download-csv';
  public csvType?: string;
  public filters?: { [key: string]: any };

  constructor(csvType?: string, filters?: { [key: string]: any }) {
    super();
    this.csvType = csvType;
    this.filters = filters;
  }
}
