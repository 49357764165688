import { GAEvent } from '@/services/ga/events/GAEvent';

export class EventFilterEntities extends GAEvent {
  public eventName = 'filter-entities';
  public filters?: { [key: string]: any };
  public savedDefault?: boolean;

  constructor(filters?: { [key: string]: any }, savedDefault?: boolean) {
    super();
    this.filters = filters;
    this.savedDefault = savedDefault;
  }
}
