
interface Sheet {
  name: string;
  data: Product[];
  bookType: 'xlsx' | 'csv';
}
import FromToDatePicker from '@/components/buttons/FromToDatePicker.vue';
import CustomField from '@/store/models/CustomField';
import { Product } from '@/store/models/Product';
import User from '@/store/modules/User';
import { getPreferredMetricWord } from '@/utils/AppName';
import { Constants } from '@/utils/Constants';
import { toSentenceCase } from '@/utils/FormattingUtils';
import { getListItem, setListItem } from '@/utils/LocalStorageUtils';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { XlsxDownload, XlsxSheet, XlsxWorkbook } from 'vue-xlsx';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    FromToDatePicker
  },
  methods: {
    toSentenceCase
  }
})
export default class CSVModal extends mixins(AppName, ConstantsMixin) {
  @Prop() loaded!: boolean;
  @Prop() refreshValues!: boolean;
  @Prop() sheets!: Sheet[];
  @Prop() productType!: string;
  @Prop() reportType!: string;
  @Prop() route!: boolean;

  @Prop() customFields!: CustomField[];
  @Prop() customEntityFields!: CustomField[];

  public allProducts = null;
  public productLevel = null;
  public collectionsDate = {
    from: dayjs()
      .subtract(2, 'day')
      .format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD')
  };
  public max = dayjs().format('YYYY-MM-DD');
  public submitted = false;
  public loading = false;
  public defaultMilkTableFields = {
    currentStateVat: [
      'supplierNumber',
      'vatNumber',
      'address',
      'containsMilk',
      'milkingInProgress',
      'capacity',
      'currentVolume',
      'milkAge',
      'milkScore',
      'lastMilking',
      'lastCollection',
      'secondLastMilking',
      'vatTemperature',
      'vatAgitator',
      'levnoInternalProductReference',
      'levnoInternalEntityReference',
      'complianceMethod'
    ],
    currentStateProduct: [
      'supplierNumber',
      'currentVolume',
      'milkScore',
      'lastMilking',
      'vatTemperature',
      'complianceMethod',
      'levnoInternalProductReference'
    ],
    collectionsVat: [
      'supplierNumber',
      'vatNumber',
      'collectionDateTime',
      'collectionType',
      'collectionVolume',
      'ageAtCollection',
      'scoreAtCollection',
      'complianceMethod',
      'temperatureAtCollection'
    ],
    collectionsProduct: [
      'supplierNumber',
      'collectionDateTime',
      'collectionType',
      'collectionVolume',
      'ageAtCollection'
    ],
    milkings: [
      'supplierNumber',
      'vatNumber',
      'milkingType',
      'milkingStartDateTime',
      'milkingStartVolume',
      'milkingStartTemp',
      'milkingFinishDateTime',
      'milkingFinishVolume',
      'milkingFinishTemp',
      'milkingTempSixHoursAfterStart',
      'milkingContainsMilkSixHoursAfterStart',
      'milkingTempTwoHoursAfterFinish',
      'milkingContainsMilkTwoHoursAfterFinish'
    ]
  };
  public defaultFuelTableFields = [
    'id',
    'productName',
    'address',
    'siteName',
    'name',
    'safeFillVolume',
    'volume',
    'dailyUse',
    'fuelType',
    'percentFull',
    'nominalVolume',
    'lastDeliveryDate',
    'estimatedEmptyDate',
    'daysTillEmpty',
    'recordedAt',
    'ullage',
    'levnoInternalProductReference',
    'levnoInternalEntityReference'
  ];
  public defaultFeedTableFields = [
    'id',
    'address',
    'siteName',
    'name',
    'volume',
    'weight',
    'dailyUse',
    'feedType',
    'percentFull',
    'nominalVolume',
    'nominalTonnage',
    'estimatedEmptyDate',
    'daysTillEmpty',
    'availableCapacity',
    'lastRefill',
    'lastRefillDate',
    'levnoInternalProductReference',
    'levnoInternalEntityReference',
    'recordedAt'
  ];
  //fuel custom fields....
  public specificReportType = '';
  public defaultFields: string[] = [];
  public selectedFields: string[] = [];
  public selectedCustomFields: string[] = [];

  public cookieSelection: string[] = [];
  public cookieCustomSelection: string[] = [];
  public restoredFromCookie = false;

  @Watch('loaded')
  sheetsLoaded() {
    //   this.loading = false;
  }

  @Watch('allProducts')
  allProductsUpdated() {
    if (
      (this.isApp(this.PRODUCT_TYPE_MILK) && this.productLevel != null) ||
      this.isApp(this.PRODUCT_TYPE_FUEL) ||
      this.isApp(this.PRODUCT_TYPE_FEED) ||
      (this.isApp(this.PRODUCT_TYPE_MILK) && this.reportType == 'milkings')
    ) {
      this.updateFields();
    }
  }

  @Watch('productLevel')
  productLevelUpdated() {
    if (this.allProducts != null) {
      this.updateFields();
    }
  }

  @Watch('refreshValues')
  resetValues() {
    this.loading = false;
    this.submitted = false;
    this.allProducts = null;
    this.productLevel = null;
    this.collectionsDate = {
      from: dayjs()
        .subtract(2, 'day')
        .format('YYYY-MM-DD'),
      to: dayjs().format('YYYY-MM-DD')
    };
    this.defaultFields = [];
    this.selectedFields = [];
    this.cookieSelection = [];
    this.cookieCustomSelection = [];
    if (
      this.isApp(this.PRODUCT_TYPE_FUEL) ||
      this.isApp(this.PRODUCT_TYPE_FEED)
    ) {
      this.selectedCustomFields = [
        ...this.customFields.map(field => field.label),
        ...this.customEntityFields.map(field => field.label)
      ];
    }

    this.restoredFromCookie = false;
  }

  get isFromBeforeTo() {
    return (
      this.collectionsDate.from == this.collectionsDate.to ||
      dayjs(this.collectionsDate.from).isBefore(dayjs(this.collectionsDate.to))
    );
  }

  public updateFields() {
    //change the selectedFields and defaultFields to match the report type
    if (this.productType == 'milk') {
      if (this.reportType == 'collections') {
        if (this.productLevel) {
          this.defaultFields = this.defaultMilkTableFields.collectionsProduct;
          this.specificReportType = 'collectionsProduct';
        } else {
          this.defaultFields = this.defaultMilkTableFields.collectionsVat;
          this.specificReportType = 'collectionsVat';
        }
      } else if (this.reportType == 'milkings') {
        this.defaultFields = this.defaultMilkTableFields.milkings;
        this.specificReportType = 'milkings';
      } else {
        if (this.productLevel) {
          this.defaultFields = this.defaultMilkTableFields.currentStateProduct;
          this.specificReportType = 'currentStateProduct';
        } else {
          this.defaultFields = this.defaultMilkTableFields.currentStateVat;
          this.specificReportType = 'currentStateVat';
        }
      }
    } else if (this.isApp(this.PRODUCT_TYPE_FEED)) {
      let fieldsToRemove: string[] = [];
      if (getPreferredMetricWord() == Constants.METRIC_WEIGHT) {
        fieldsToRemove = ['nominalVolume', 'volume'];
      } else {
        fieldsToRemove = ['nominalTonnage', 'weight'];
      }
      this.defaultFields = this.defaultFeedTableFields.filter(
        field => !fieldsToRemove.includes(field)
      );
      this.specificReportType = 'currentStateFeed';
    } else {
      this.defaultFields = this.defaultFuelTableFields;
      this.specificReportType = 'currentState';
    }
    // this.defaultFields = this.collections
    //   ? this.productLevel
    //     ? this.defaultMilkTableFields.collectionsProduct
    //     : this.defaultMilkTableFields.collectionsVat
    //   : this.productLevel
    //   ? this.defaultMilkTableFields.currentStateProduct
    //   : this.defaultMilkTableFields.currentStateVat;
    if (User._token?.orgs[User._orgIndex].orgId == 6700) {
      this.defaultFields = [
        ...this.defaultFields,
        ...(!this.productLevel
          ? this.reportType == 'collections'
            ? ['tempAtCollection']
            : ['vatTemp', 'vatAgitator']
          : [])
      ];
    }

    const fieldsFromCookie = getListItem(this.specificReportType);
    if (
      fieldsFromCookie.length > 0 &&
      fieldsFromCookie.length != this.defaultFields.length
    ) {
      this.cookieSelection = fieldsFromCookie;
    }
    this.selectedFields = this.defaultFields;
    if (
      this.isApp(this.PRODUCT_TYPE_FUEL) ||
      this.isApp(this.PRODUCT_TYPE_FEED)
    ) {
      const customFieldsFromCookie = getListItem(
        `${this.productType}CSVCustomFieldSelection`
      );
      if (
        customFieldsFromCookie.length > 0 &&
        customFieldsFromCookie.length !=
          this.customFields.length + this.customEntityFields.length
      ) {
        this.cookieCustomSelection = customFieldsFromCookie;
      }
    }
  }

  public generateExport() {
    this.submitted = true;
    this.loading = true;
    setListItem(this.specificReportType, this.selectedFields);
    if (
      this.isApp(this.PRODUCT_TYPE_FUEL) ||
      this.isApp(this.PRODUCT_TYPE_FEED)
    ) {
      setListItem(
        `${this.productType}CSVCustomFieldSelection`,
        this.selectedCustomFields
      );
      this.$emit(
        'generateReport',
        this.allProducts,
        this.productLevel,
        this.collectionsDate,
        [...this.selectedFields, ...this.selectedCustomFields]
      );
    } else {
      this.$emit(
        'generateReport',
        this.allProducts,
        this.productLevel,
        this.collectionsDate,
        this.selectedFields
      );
    }
  }

  get filename() {
    return this.reportType == 'collections'
      ? this.collectionsFileName
      : this.reportType == 'milkings'
      ? this.milkingsFilename
      : this.currentStateFilename;
  }

  get timestamp() {
    return dayjs().format('HHmm_DD-MM-YYYY');
  }

  get collectionsFileName() {
    return (
      this.productType +
      'Collections_' +
      this.collectionsDate.from +
      '-' +
      this.collectionsDate.to +
      '.csv'
    );
  }

  get currentStateFilename() {
    return this.productType + 'CurrentState_' + this.timestamp + '.csv';
  }

  get milkingsFilename() {
    return 'milkings_' + this.timestamp + '.csv';
  }

  public restoreFromCookie() {
    if (this.cookieSelection.length != 0) {
      this.selectedFields = this.cookieSelection;
    }
    if (this.cookieCustomSelection.length != 0) {
      if (
        this.isApp(this.PRODUCT_TYPE_FUEL) ||
        this.isApp(this.PRODUCT_TYPE_FEED)
      ) {
        this.selectedCustomFields = this.cookieCustomSelection;
      }
    }
  }

  public closeModal() {
    this.$root.$emit('bv::hide::modal', 'csvModal', '#btnHide');
  }
}
