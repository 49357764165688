
import { getFormattedFeedAmount } from '@/utils/FeedUtils';
import { StringKeyObject } from '@/utils/types/StringKeyObject';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  methods: {
    getFormattedFeedAmount
  }
})
export default class RouteVolumeReading extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop() routeVolume!: number;

  public getRouteImage() {
    return require(`../../assets/route-plan-active-${this.getAppName(
      false
    )}.svg`);
  }

  get title() {
    const volumeType: StringKeyObject = {
      [this.PRODUCT_TYPE_MILK]: 'volume',
      [this.PRODUCT_TYPE_FUEL]: 'ullage',
      [this.PRODUCT_TYPE_FEED]: 'available capacity'
    };
    return `Total ${volumeType[this.getAppName(false)]} on the selected route`;
  }
}
