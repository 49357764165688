var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BModal',{ref:"collectModal",staticClass:"d-flex",attrs:{"id":"downloadCollections","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title w-100 text-center",staticStyle:{"margin-right":"-44px"},attrs:{"id":"downloadCollections___BV_modal_title_"}},[_vm._v(" "+_vm._s(!_vm.collectionsDownload ? 'Select Collections Time Period' : !_vm.loaded ? 'Generating CSV' : !_vm.error ? 'Collections CSV Ready to Download' : 'CSV Error')+" ")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.closeCollectionsModal()}}},[_vm._v(" × ")])]},proxy:true}])},[(!_vm.loaded && _vm.collectionsDownload)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"mt-4 text-center",attrs:{"variant":"secondary","id":"spinner3"}})],1):_vm._e(),(!_vm.collectionsDownload)?_c('div',[_c('BRow',[_c('BCol',[_c('label',{attrs:{"for":"collectionDateFrom"}},[_vm._v("From")]),_c('BFormDatepicker',{staticClass:"mb-2",attrs:{"id":"collectionDateFrom","date-format-options":{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short'
            },"state":_vm.collectionsDate.from == '' || _vm.collectionsDate.to == ''
                ? null
                : _vm.isFromBeforeTo,"max":_vm.max},model:{value:(_vm.collectionsDate.from),callback:function ($$v) {_vm.$set(_vm.collectionsDate, "from", $$v)},expression:"collectionsDate.from"}})],1),_c('BCol',[_c('label',{attrs:{"for":"collectionDateTo"}},[_vm._v("To")]),_c('BFormDatepicker',{staticClass:"mb-2",attrs:{"id":"collectionDateTo","date-format-options":{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short'
            },"state":_vm.collectionsDate.from == '' || _vm.collectionsDate.to == ''
                ? null
                : _vm.isFromBeforeTo,"max":_vm.max},model:{value:(_vm.collectionsDate.to),callback:function ($$v) {_vm.$set(_vm.collectionsDate, "to", $$v)},expression:"collectionsDate.to"}})],1)],1),(
          _vm.collectionsDate.from != '' &&
            _vm.collectionsDate.to != '' &&
            !_vm.isFromBeforeTo
        )?_c('p',{staticClass:"text-danger text-center"},[_vm._v(" From date must be before to date ")]):_vm._e()],1):_vm._e(),(!_vm.collectionsDownload)?_c('div',{staticClass:"d-flex mx-auto"},[_c('BButton',{staticClass:"mx-auto mt-3",attrs:{"variant":"grey","disabled":_vm.collectionsDate.from == '' ||
            _vm.collectionsDate.to == '' ||
            !_vm.isFromBeforeTo,"title":_vm.collectionsDate.from == '' ||
          _vm.collectionsDate.to == '' ||
          !_vm.isFromBeforeTo
            ? 'Please choose valid dates for the collections'
            : 'Generate Collections CSV'},on:{"click":_vm.downloadCollections}},[_vm._v(" Generate Collections Data ")])],1):_vm._e(),(!_vm.loaded && _vm.collectionsDownload)?_c('p',{staticClass:"text-center mt-2",attrs:{"variant":"secondary"}},[_vm._v(" Loading data... ")]):_vm._e(),(_vm.loaded && _vm.sheets[0].data.length == 0 && _vm.collectionsDownload)?_c('p',{staticClass:"text-center mt-2"},[_vm._v(" "+_vm._s(_vm.error ? 'There was an error, please contact Levno' : 'No products to download')+" ")]):_vm._e(),(_vm.sheets[0].data.length > 0 && _vm.loaded && _vm.collectionsDownload)?_c('xlsx-workbook',{staticClass:"d-flex mx-auto"},[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":sheet.data,"sheet-name":sheet.name}})}),_c('xlsx-download',{staticClass:"d-flex mx-auto",attrs:{"filename":_vm.filename}},[_c('BButton',{staticClass:"mx-auto",attrs:{"variant":"grey"}},[_vm._v(" Download collections"),_c('BIcon',{staticClass:"ml-1",attrs:{"icon":"download"}})],1)],1)],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }