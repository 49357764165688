
import { getObjectItem, setObjectItem } from '@/utils/LocalStorageUtils';
import { isWaterMeter, isWaterTank } from '@/utils/WaterUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop, Emit } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component({
  methods: {
    isWaterMeter
  }
})
export default class UnitSettingsModal extends mixins(AppName) {
  @Prop({ required: true, type: Number }) width!: number;
  public flowRateUnitValues = [
    { value: 'L/s', label: 'Litres / Second (L/s)' },
    { value: 'L/m', label: 'Litres / Minute (L/m)' }
  ];
  public usageUnitValues = [
    { value: 'm\u00B3', label: 'Metres Cubed (m\u00B3)' },
    { value: 'L', label: 'Litres (L)' }
  ];

  public selectedCurrentUsageValue: null | string = null;
  public selectedUsageValue: null | string = null;

  public onOpen(): void {
    const savedUnits = getObjectItem(`unitSettings-${this.getEntityApiWord()}`);
    if (savedUnits) {
      this.selectedCurrentUsageValue = savedUnits.flowRateUnit;
      this.selectedUsageValue = savedUnits.usageUnit;
    }
    if (isWaterTank() && (!savedUnits || !savedUnits.usageUnit)) {
      this.selectedUsageValue = this.usageUnitValues[1].value;
    }
  }

  mounted() {
    if (this.selectedCurrentUsageValue === null) {
      this.selectedCurrentUsageValue = this.flowRateUnitValues[0].value;
    }
    if (this.selectedUsageValue === null) {
      this.selectedUsageValue = this.usageUnitValues[0].value;
    }
  }

  created() {
    const savedUnits = getObjectItem(`unitSettings-${this.getEntityApiWord()}`);
    if (savedUnits) {
      this.selectedCurrentUsageValue = savedUnits.flowRateUnit;
      this.selectedUsageValue = savedUnits.usageUnit;
    }
  }

  @Emit('unitSettingsUpdated')
  public saveSettings() {
    const unitSettings: any = {
      flowRateUnit: this.selectedCurrentUsageValue,
      usageUnit: this.selectedUsageValue
    };
    setObjectItem(`unitSettings-${this.getEntityApiWord()}`, unitSettings);
    this.closeUnitModal();
  }

  public closeUnitModal() {
    this.$root.$emit('bv::hide::modal', 'unitSettingsModal', '#btnHide');
  }
}
