import { render, staticRenderFns } from "./CollectionsCSVModal.vue?vue&type=template&id=49207314&scoped=true&"
import script from "./CollectionsCSVModal.vue?vue&type=script&lang=ts&"
export * from "./CollectionsCSVModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49207314",
  null
  
)

export default component.exports