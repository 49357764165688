
interface Sheet {
  name: string;
  data: Product[];
  bookType: 'xlsx' | 'csv';
}
import { Product } from '@/store/models/Product';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { XlsxDownload, XlsxSheet, XlsxWorkbook } from 'vue-xlsx';

@Component({
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  }
})
export default class CurrentStateCSVModal extends Vue {
  @Prop() loaded!: boolean;
  @Prop() sheets!: Sheet[];
  @Prop() filename!: string;
  @Prop() error!: boolean;

  public closeCurrentStateModal() {
    this.$root.$emit('bv::hide::modal', 'downloadCurrentState', '#btnHide');
  }
}
