import { GAEvent } from '@/services/ga/events/GAEvent';
import { EntityApiType } from '@/utils/types/EntityApiType';

export class EventSidebarChangeEntity extends GAEvent {
  public eventName = 'sidebar-change-entity';
  public prevEntityId?: number;
  public prevEntityType?: EntityApiType;
  public nextEntityId?: number;
  public nextEntityType?: EntityApiType;

  constructor(
    prevEntityId?: number,
    prevEntityType?: EntityApiType,
    nextEntityId?: number,
    nextEntityType?: EntityApiType
  ) {
    super();

    this.prevEntityId = prevEntityId;
    this.prevEntityType = prevEntityType;
    this.nextEntityId = nextEntityId;
    this.nextEntityType = nextEntityType;
  }
}
